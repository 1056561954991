import ContactForm from './ContactForm'

import Zach from './assets/zach.jpeg'

import './index.css'

const generateBoxBg = () => Math.floor(Math.random() * 270 + 200)

const TeamContact = () => (
  <>
    <section className="container mx-auto px-6">
      <div className="flex justify-center">
        <h3
          className="text-center text-2xl md:text-3xl mb-20 md:mb-28 font-bold section-title uppercase "
          title="Team"
        >
          Team
        </h3>
      </div>
      <div className="max-w-screen-xl mx-auto mb-40">
        <div className="max-w-md mx-auto">
          <div
            className="p-1 rounded-tl-lg rounded-br-lg animate-box shadow-2xl pb-6"
            style={{
              background: `linear-gradient(${generateBoxBg()}deg, #e0197b, #7563ce)`,
              backgroundSize: '140% 140%',
            }}
          >
            <div className="flex flex-col items-center p-8 bg-white mb-6 rounded-tl-lg rounded-br-lg text-center h-full">
              <div
                className="p-2 rounded-tl-lg rounded-br-lg animate-box mb-7"
                style={{
                  background: `linear-gradient(${generateBoxBg()}deg, #e0197b, #7563ce)`,
                  backgroundSize: '140% 140%',
                }}
              >
                <img
                  src={Zach}
                  alt="Zach Shaw"
                  className="w-40 rounded-tl-lg rounded-br-lg"
                />
              </div>
              <h3 className="font-bold mb-3 uppercase my-3 section-title text-lg">
                Zach Shaw
              </h3>
              <h4
                className="font-bold section-title uppercase italic text-sm mb-8"
                style={{ color: '#e0197b' }}
              >
                Director &amp; Engineer
              </h4>
              <p className="text-sm mb-4">
                Seasoned software engineer with 10 years experience within both
                enterprise and startups.
              </p>
              <p className="text-sm">
                Zach's worked across multiple sectors, including e-commerce,
                supply chain, gaming, telecomms and more.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-screen-sm mx-auto">
        <div className="flex justify-center">
          <h3
            className="text-center text-2xl md:text-3xl mb-20 md:mb-28 font-bold section-title uppercase "
            title="Contact"
          >
            Contact
          </h3>
        </div>
        <ContactForm />
      </div>
    </section>
  </>
)

export default TeamContact
