import { ReactNode } from 'react'
import { ReactSVG } from 'react-svg'
import outer from './outer_animated.svg'
import inner from './inner.svg'

import './index.css'

type Props = {
  children: ReactNode
}

const Logo = ({ children }: Props) => {
  return (
    <div className="flex flex-col items-center text-white">
      <div className="relative">
        <ReactSVG src={outer} className="w-60 md:w-96" />
        <img id="planet" src={inner} alt="planet" className="absolute top-0" />
      </div>
      {children}
    </div>
  )
}

export default Logo
