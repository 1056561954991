import cn from 'classnames'
import { Link } from 'react-scroll'

import Logo from '../../../components/Logo'
import Chevron from './chevron.svg'
import './index.css'

const Hero = () => (
  <div className="mb-16 md:mb-56 w-full">
    <div className="hero-container flex items-center justify-center">
      <Logo>
        <h1 className="text-top">Shaw</h1>
        <h3 className="text-bottom">Industries</h3>
      </Logo>
      <div className="absolute focus:outline-none hero-btn-container cursor-pointer">
        <div className="flex relative">
          <Link to="services" smooth={true} delay={300} duration={1200}>
            <div className={cn('flex items-center justify-center pulse')} />
            <img src={Chevron} alt="chevron" className="w-5 chevron absolute" />
          </Link>
        </div>
      </div>
    </div>
    <svg
      width="500"
      height="100"
      viewBox="0 0 500 80"
      preserveAspectRatio="none"
      className="w-full"
      id="services"
    >
      <path
        d="M0,0 L0,40 Q250,100 500,40 L500,0 Z"
        fill="hsl(225.81, 68.23%, 57.85%)"
      />
    </svg>
  </div>
)

export default Hero
