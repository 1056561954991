import LinkedIn from './linkedin.svg'

const Footer = () => (
  <footer
    className="absolute -bottom-96 right-0 left-0 text-white py-8"
    style={{
      background: `linear-gradient(45deg, #e0197b, #7563ce)`,
      backgroundSize: '150% 150%',
    }}
  >
    <div className="max-w-screen-sm mx-auto px-6 md:px-0 font-bold md:flex items-end justify-between">
      <div className="uppercase">
        <p className="text-4xl">Shaw</p>
        <p className="mb-1 italic tracking-widest" style={{ fontSize: '15px' }}>
          Industries
        </p>
        <div className="text-xs mb-4 md:mb-0">
          <p>Copyright © - Shaw Industries Ltd ({new Date().getFullYear()})</p>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://find-and-update.company-information.service.gov.uk/company/10852313"
            className="underline"
          >
            10852313
          </a>
        </div>
      </div>
      <div className="flex flex-col md:items-end text-sm md:text-right italic">
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.linkedin.com/company/shaw-industries-ltd"
        >
          <img src={LinkedIn} alt="linkedin" className="w-6 mb-2 self-end" />
        </a>
        <div>
          Service icons by{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.flaticon.com/authors/xnimrodx"
            title="xnimrodx"
            className="underline"
          >
            xnimrodx
          </a>
        </div>
        <div>
          Tech icons by{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://freeicons.io/profile/3"
            title="icon king1"
            className="underline"
          >
            icon king1
          </a>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
