import React from './assets/react.svg'
import Typescript from './assets/typescript.svg'
import Node from './assets/node.svg'
import GraphQL from './assets/graphql.svg'
import CircleCI from './assets/circleci.svg'
import Netlify from './assets/netlify.svg'
import Boots from './assets/boots.svg'
import Drest from './assets/drest.svg'
import Idean from './assets/idean.svg'
import Play from './assets/play.svg'
import Syzygy from './assets/syzygy.svg'

import './index.css'

export type Tech = {
  title: string
  image: string
}

const techCopy: Tech[] = [
  {
    title: 'React',
    image: React,
  },
  {
    title: 'Typescript',
    image: Typescript,
  },
  {
    title: 'Node',
    image: Node,
  },
  {
    title: 'GraphQL',
    image: GraphQL,
  },
  {
    title: 'CircleCI',
    image: CircleCI,
  },
  {
    title: 'Netlify',
    image: Netlify,
  },
]

const Technology = () => (
  <>
    <div className="tech-container pt-32 md:pt-56">
      <div className="relative max-w-screen-lg mx-auto">
        <section className="container mx-auto px-6 mb-10">
          <div className="flex justify-center mb-20 md:mb-20">
            <h3
              className="text-center text-2xl md:text-3xl font-bold section-title uppercase "
              title="Technology"
            >
              Technology
            </h3>
          </div>
          <div className="static mb-20 md:mb-52 shadow-2xl py-28 md:px-12 border-4 border-white border-opacity-10 max-w-screen-lg mx-auto rounded-tl-lg rounded-br-lg">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-20">
              {techCopy.map((t) => (
                <div key={t.title} className="flex flex-col items-center">
                  <img
                    src={t.image}
                    alt="devlopment"
                    className="service-icons mb-8"
                  />
                  <h3 className="font-bold section-title">{t.title}</h3>
                </div>
              ))}
            </div>
          </div>
          <div className="history-wrapper text-center font-bold mb-20 md:mb-40">
            <div className="flex justify-center mb-20 md:mb-20">
              <h3
                className="text-2xl md:text-3xl section-title uppercase "
                title="History"
              >
                History
              </h3>
            </div>
            <div className="shadow-2xl py-28 md:px-12 border-4 border-white border-opacity-10 max-w-screen-lg mx-auto rounded-tl-lg rounded-br-lg">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-20 auto-rows-fr auto-cols-fr mb-20 xl:mb-32">
                <a
                  className="flex items-center"
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.drest.com"
                >
                  <img src={Drest} alt="drest-logo" />
                </a>
                <a
                  className="flex items-center"
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.idean.com"
                >
                  <img src={Idean} alt="idean-logo" />
                </a>
                <a
                  className="flex items-center"
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.syzygy.net"
                >
                  <img src={Syzygy} alt="syzygy-logo" />
                </a>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-20 auto-rows-fr auto-cols-fr">
                <a
                  className="flex items-center"
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.play-consult.co.uk"
                >
                  <img src={Play} alt="play-logo" />
                </a>
                <a
                  className="flex items-center"
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.boots.com"
                >
                  <img src={Boots} alt="boots-logo" />
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <svg
      width="500"
      height="100"
      viewBox="0 0 500 80"
      preserveAspectRatio="none"
      id="hero-arc"
      className="mb-16 md:mb-52"
    >
      <path
        d="M0,0 L0,40 Q250,100 500,40 L500,0 Z"
        fill="hsl(225.81, 68.23%, 57.85%)"
      />
    </svg>
  </>
)

export default Technology
