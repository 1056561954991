import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { RoutePath } from './pages/routePaths'

import Home from './pages/Home'
import Footer from './components/Footer'

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route path={RoutePath.Home} component={Home} />
        </Switch>
      </Router>
      <Footer />
    </>
  )
}

export default App
