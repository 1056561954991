import { useState } from 'react'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import * as emailjs from 'emailjs-com'
import * as R from 'ramda'

type FormInput = {
  firstName: string
  lastName?: string
  email: string
  message: string
}

const ContactForm = () => {
  const [formSubmitted, setFormSubmitted] = useState<string | null>(null)
  const { register, handleSubmit, errors, reset } = useForm()

  const onSubmit = async ({
    firstName,
    lastName,
    email,
    message,
  }: FormInput) => {
    const templateParams = {
      from_name: firstName,
      last_name: lastName,
      from_email: email,
      subject: `New message from ${firstName}`,
      message,
    }

    try {
      setFormSubmitted('LOADING')
      await emailjs.send(
        process.env['REACT_APP_EMAIL_SERVICE'] as string,
        process.env['REACT_APP_EMAIL_TEMPLATE'] as string,
        templateParams,
        process.env['REACT_APP_EMAIL_USER'] as string
      )
      setFormSubmitted('SUCCESS')
      resetForm()
    } catch {
      setFormSubmitted('ERROR')
      resetForm()
    }
  }

  const resetForm = () => {
    const resetAll = () => {
      setFormSubmitted(null)
      reset()
    }
    setTimeout(() => resetAll(), 3000)
  }

  const submitStatusMessage = () => {
    switch (formSubmitted) {
      case 'SUCCESS':
        return 'Message Sent!'
      case 'ERROR':
        return 'Error Sending Message'
      default:
        return 'Send'
    }
  }

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="grid-first-name"
          >
            First Name *
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            id="grid-first-name"
            type="text"
            name="firstName"
            ref={register({ required: true })}
          />
          {errors.firstName && (
            <p className="text-red-500 text-xs italic">
              Please fill out this field.
            </p>
          )}
        </div>
        <div className="w-full md:w-1/2 px-3">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="grid-last-name"
          >
            Last Name
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="grid-last-name"
            type="text"
            name="lastName"
            ref={register}
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            E-mail *
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="email"
            type="email"
            name="email"
            ref={register({ required: true })}
          />
          {errors.email && (
            <p className="text-red-500 text-xs italic">
              Please fill out this field.
            </p>
          )}
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Message *
          </label>
          <textarea
            className=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
            id="message"
            name="message"
            ref={register({ required: true })}
          />
          {errors.message && (
            <p className="text-red-500 text-xs italic">
              Please fill out this field.
            </p>
          )}
        </div>
      </div>
      <input
        className={cn(
          'w-full shadow focus:outline-none text-white font-bold py-2 px-4 rounded text-sm cursor-pointer',
          {
            'bg-purple-500': R.isEmpty(errors),
            'bg-red-500':
              !R.isEmpty(errors) || R.equals(formSubmitted, 'ERROR'),
            'bg-pink-600': R.equals(formSubmitted, 'SUCCESS'),
          }
        )}
        type="submit"
        value={submitStatusMessage()}
      />
    </form>
  )
}

export default ContactForm
