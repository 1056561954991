import { ServicesCopy, Service } from './services'
import './index.css'

const generateBoxBg = () => Math.floor(Math.random() * 270 + 200)

const Services = () => (
  <>
    <section className="container mx-auto px-6 mb-16 md:mb-56">
      <div className="flex justify-center">
        <h3
          className="text-center text-2xl md:text-3xl mb-20 md:mb-28 font-bold section-title uppercase "
          title="Services"
        >
          Services
        </h3>
      </div>
      <div className="max-w-screen-xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 md:gap-20 auto-rows-fr auto-cols-fr mb-20">
          {ServicesCopy.map((service: Service) => (
            <div
              key={service.title}
              className="p-1 rounded-tl-lg rounded-br-lg animate-box shadow-2xl pb-6"
              style={{
                background: `linear-gradient(${generateBoxBg()}deg, #e0197b, #7563ce)`,
                backgroundSize: '140% 140%',
              }}
            >
              <div className="flex flex-col items-center p-8 bg-white mb-6 rounded-tl-lg rounded-br-lg text-center h-full">
                <img
                  src={service.image}
                  alt="devlopment"
                  className="w-12 mb-4"
                />
                <h3 className="font-bold mb-3">{service.title}</h3>
                <p>{service.copy}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    <svg
      width="500"
      height="100"
      viewBox="0 0 500 80"
      preserveAspectRatio="none"
      id="hero-arc"
    >
      <path d="M0,0 L0,40 Q250,100 500,40 L500,0 Z" fill="#FFF" />
    </svg>
  </>
)

export default Services
