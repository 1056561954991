import Dev from './assets/webdev.svg'
import Deploy from './assets/deploy.svg'
import Api from './assets/api.svg'
import CiCd from './assets/cicd.svg'
import ThirdParty from './assets/third-party.svg'
import Architecture from './assets/architecture.svg'

export type Service = {
  title: string
  image: string
  copy: string
}

const ServicesCopy: Service[] = [
  {
    title: 'Web Development',
    image: Dev,
    copy: `Fast & responsive static sites or SPA's built using the latest
    frontend tech.`,
  },
  {
    title: 'API Development',
    image: Api,
    copy: `Custom backend API's for serving and storing data across your application.`,
  },
  {
    title: 'Third Party API Integrations',
    image: ThirdParty,
    copy: `Integration with any third-party API's, providing access to external data and services.`,
  },
  {
    title: 'App Architecture',
    image: Architecture,
    copy: `Applications designed from the ground up, enhancing performance with less code.`,
  },
  {
    title: 'CI/CD Integration',
    image: CiCd,
    copy: `Integration with CI/CD platforms to effectively manage deployment pipelines.`,
  },
  {
    title: 'Deployment',
    image: Deploy,
    copy: `Lightning fast automated and serverless deployment of your web applications.`,
  },
]

export { ServicesCopy }
