import Hero from './Hero'
import Services from './ProvidedServices'
import TechHistory from './TechHistory'
import TeamContact from './TeamContact'

const Home = () => (
  <>
    <Hero />
    <Services />
    <TechHistory />
    <TeamContact />
  </>
)

export default Home
